import { isNumber } from "../util/number"

const usePromo = () => {
  const promoTypes = [
    { label: "Fixed discount", value: "fixedCart" },
    // { label: "Fixed cart discount", value: "fixed_cart" },
    // { label: "Fixed product discount", value: "fixedProduct" },
    { label: "Percentage discount", value: "percent" },
  ]

  const formatDiscount = (value, type) => {
    const val = isNumber(value) ? value : 0
    if (type === "percent") {
      return `${val}%`
    } else {
      return val
    }
  }

  const validateAmount = (value, type) => {
    let val = isNumber(value) ? value : 0
    val = parseFloat(val)
    if (type === "percent") {
      return val > 100 ? 100 : val
    } else {
      return val
    }
  }

  const formatType = (type) => {
    let tmp = promoTypes.find(item => item.value === type)
    return tmp ? tmp.label : ""
  }

  const calculateDiscount = (subTotal, discount, type = "percent") => {
    const disc = isNumber(discount) ? discount : 0
    if (type === "percent") {
      const val = isNumber(subTotal) ? subTotal : 0
      return val * (disc / 100)
    }
    return disc
  }

  const totalWithDiscount = (subTotal, discount, type = 'percent') => {
    const val = isNumber(subTotal) ? subTotal : 0
    const disc = isNumber(discount) ? discount : 0
    if (type === "percent") {
      return val - (val * (disc / 100))
    }
    return val - disc
  }

  return {
    promoTypes,
    formatDiscount,
    formatType,
    validateAmount,
    calculateDiscount,
    totalWithDiscount
  }
}

export default usePromo
