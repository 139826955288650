import React, { useContext, useEffect, useReducer } from "react"
import PropTypes from 'prop-types'
import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, orderBy, query, serverTimestamp, updateDoc, where } from "@firebase/firestore"
import { toast } from "react-toastify"

import { db } from "../../firebase"
import { showLoader } from "../../utils/loader"
import { defaultList, defaultPromo } from "../util/defaultData"
import { useECommerceStore, useProduct, usePromo, useTax } from ".."

const Store = React.createContext()

const cartRef = "carts"
const promoRef = "promoCodes"

export function useCart() {
  return useContext(Store)
}

const initialState = {
  cart: defaultList,
  summary: {
    ...defaultList,
    subTotal: 0,
    total: 0,
  },
  promo: defaultPromo,
  subTotal: 0,
  total: 0,
  appoinment: ''
}

function reducer(state, action) {
  switch (action.type) {
    case "products": {
      return {
        ...state,
        products: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "cart": {
      return {
        ...state,
        cart: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "summary": {
      return {
        ...state,
        summary: {
          items: action.items,
          // subTotal: action.subTotal,
          // total: action.total,
          status: action.status
        },
        subTotal: action.subTotal,
        total: action.total
      }
    }
    case "promo": {
      return {
        ...state,
        promo: {
          ...action.items,
          status: action.status
        }
      }
    }
    case "total": {
      return {
        ...state,
        total: action.value
      }
    }
    case "subTotal": {
      return {
        ...state,
        subTotal: action.value
      }
    }
    case "appoinment": {
      return {
        ...state,
        appoinment: action.value
      }
    }
    default:
      return state
  }
}

export default function CardProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { promo, cart } = state
  const { tax } = useECommerceStore()
  const { calculateDiscount } = usePromo()
  const { calculateTax, totalWithTax } = useTax()

  const { products } = useProduct()

  useEffect(() => {
    let totalPaybalAmount = 0, subTotal = 0, summary = []
    if (cart.status !== 'loading') {
      subTotal = cart?.items.reduce((prev, cur) => prev + (cur.qty * cur.cost) + (cur?.service?.charge || 0), 0)
      // subTotal = cart?.items.reduce((prev, cur) => cur?.service?.charge ? prev + (cur.qty * cur.cost) + cur?.service?.charge : prev + (cur.qty * cur.cost), 0)
      if (subTotal > 0) {
        summary.push({
          name: 'Sub Total',
          amount: subTotal
        })
      }
      totalPaybalAmount = subTotal
      if (promo && promo?.amount > 0) {
        let discount = calculateDiscount(totalPaybalAmount, promo.amount, promo?.type)
        summary.push({
          // element: <>
          //   <span>Discount </span>
          //   <br /><b>{promo.code}</b>
          // </>,
          name: "Discount",
          meta: `Code: ${promo.code}`,
          value: promo.code,
          amount: -discount
        })
        totalPaybalAmount = totalPaybalAmount - discount
      }
      if (tax && tax.rate > 0) {
        summary.push({
          // element: <><span>{tax.name} </span><span>{tax.rate}%</span></>,
          name: tax.name,
          meta: `${tax.rate}%`,
          value: tax.rate,
          amount: calculateTax(totalPaybalAmount, tax.rate)
        })
        totalPaybalAmount = totalWithTax(totalPaybalAmount, tax.rate)
      }
      dispatch({
        type: "summary",
        items: summary,
        total: totalPaybalAmount,
        subTotal: subTotal,
        status: "idel"
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tax, cart, promo])

  const getCart = (uid) => {
    onSnapshot(query(collection(db, cartRef), orderBy("createdAt"), where("uid", "==", uid)), querySnapshot => {
      var items = []
      querySnapshot.forEach((snapDoc) => {
        if (snapDoc.exists) {
          let data = snapDoc.data(), prod, cartItem, service = {
            charge: 0,
            name: ""
          }
          prod = products.items.find(item => item.uid === data.pid)
          if (prod) {
            cartItem = {
              // ...doc.data(),
              // id: item.id,
              // qty: data.qty,
              // product: prod,
              // name: prod.name,
              // cost: parseFloat(prod.cost),
              // serviceCharge: 0,
              ...data,
              // uid: item.uid,
              cid: snapDoc.id,
              service: data.service || service
            }
            items.push(cartItem)
          } else {
            deleteDoc(doc(db, cartRef, snapDoc.id))
            toast.error(`${data.name} removed from your cart.`)
          }
        }
      })
      dispatch({
        type: "cart",
        items: items,
        status: "idel"
      })
    })
  }

  const addToCart = (uid, cartData) => new Promise((resolve, reject) => {
    if (!uid) {
      toast.error("Please login first.")
      return
    }
    // console.log("addToCart", data)
    showLoader(true)
    getDocs(query(collection(db, cartRef), where("uid", "==", uid), where("pid", "==", cartData.pid))).then(querySnapshot => {
      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach((docData) => {
          if (docData.exists) {
            let data = docData.data()
            // console.log(data)
            cartData.qty = parseInt(cartData.qty) + parseInt(data.qty)
            return updateDoc(doc(db, cartRef, docData.id), cartData)
              .then(resData => resolve(resData))
              .catch(error => reject(error))
              .finally(() => {
                showLoader(false)
              })
          }
        })
      } else {
        cartData.uid = uid
        cartData.createdAt = serverTimestamp()
        return addDoc(collection(db, cartRef), cartData)
          .then(resData => resolve(resData))
          .catch(error => reject(error))
          .finally(() => {
            showLoader(false)
          })
      }
    })
  })

  const removeFromCart = (cid, loader = true) => {
    console.log("removeFromCart", cid)
    loader && showLoader(true)
    // toast.success("Item removed from cart")
    return deleteDoc(doc(db, cartRef, cid)).finally(() => {
      loader && showLoader(false)
    })
  }

  const updateQty = (cid, qty, loader = true) => {
    console.log("updateQty", cid, qty)
    loader && showLoader(true)
    return updateDoc(doc(db, cartRef, cid), "qty", qty).finally(() => {
      loader && showLoader(false)
    })
  }

  const applyPromo = (code) => {
    showLoader(true)
    getDocs(query(collection(db, promoRef), where("code", "==", code)))
      .then((res) => {
        if (res?.docs?.length > 0) {
          res.forEach(d => {
            let data = d.data()
            if (data.usage === data.limit) {
              toast.error("Promo code expired.")
              return
            }
            dispatch({
              type: "promo",
              items: data,
              status: "idel"
            })
            toast.success("Promo code applied.")
          })
        } else {
          toast.error("Promo code not found.")
        }
      })
      .catch(error => {
        toast.error("Something went wrong!")
        console.log(error)
      })
      .finally(() => {
        showLoader(false)
      })
  }

  const clearPromo = () => {
    dispatch({
      type: "promo",
      items: defaultPromo,
      status: "idel"
    })
    toast.success("Promo code removed.")
  }

  const setAppoinment = value => {
    dispatch({
      type: "appoinment",
      value
    })
  }

  // console.log("CartContext", state)

  return (
    <Store.Provider value={{
      ...state,
      dispatch,
      getCart,
      addToCart,
      removeFromCart,
      updateQty,
      applyPromo,
      clearPromo,
      setAppoinment
    }}>
      {children}
    </Store.Provider>
  )
}

CardProvider.propTypes = {
  children: PropTypes.node.isRequired
}