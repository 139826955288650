import { initializeApp } from "firebase/app"
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getFunctions } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbt8_nyUp9NNj7pGHLD4BaxDGgze5AG_s",
  authDomain: "fixwagen.firebaseapp.com",
  projectId: "fixwagen",
  storageBucket: "fixwagen.appspot.com",
  messagingSenderId: "220580421945",
  appId: "1:220580421945:web:eeb45720adcb7b30f908e8",
  measurementId: "G-P6C9D0TER4"
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

// module
const analytics = getAnalytics(firebaseApp)
const storage = getStorage(firebaseApp)
const db = getFirestore()
const auth = getAuth()
const functions = getFunctions(firebaseApp);

if (window.location.hostname === "localhost") {
  setAnalyticsCollectionEnabled(analytics, false)
}

const collectionsRef = {
  users: "users",
  options: "options",
  models: "models",

}

export {
  collectionsRef,
  auth,
  analytics,
  firebaseApp,
  db,
  storage,
  functions,
  firebaseConfig,
}
