export const defaultList = {
  status: "loading",
  items: []
}

export const defaultPromo = {
  code: '',
  discount: 0
}

export const defaultTax = {
  name: "",
  rate: 0
}

export const defaultCurrency = {
  name: "US Daller",
  iso: "USD"
}
