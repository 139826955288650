import React, { useEffect, useContext, useReducer } from "react"
import { getFirestore, collection, doc, getDoc, getDocs, onSnapshot, query, where, orderBy } from "@firebase/firestore"

const db = getFirestore()

const initialState = {
  manufacturer: {
    status: "loading",
    items: []
  },
  models: {
    status: "idel",
    items: []
  },
  modelsList: {
    status: "idel",
    items: []
  },
  cities: {
    status: "loading",
    items: []
  },
  options: {
    status: "loading",
    items: []
  },
  slider: {
    status: "loading",
    items: []
  },
  events: {
    status: "loading",
    items: []
  }
}

const FirestoreContext = React.createContext()

export function useFirestore() {
  return useContext(FirestoreContext)
}

function reducer(state, action) {
  switch (action.type) {
    case "manufacturer": {
      return {
        ...state,
        manufacturer: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "models": {
      return {
        ...state,
        models: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "modelsList": {
      return {
        ...state,
        modelsList: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "cities": {
      return {
        ...state,
        cities: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "options": {
      return {
        ...state,
        options: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "slider": {
      return {
        ...state,
        slider: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "events": {
      return {
        ...state,
        events: {
          items: action.items,
          status: action.status
        }
      }
    }
    default:
      return state
  }
}

export default function FirestoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const date = new Date()
  const yyyy = date.getFullYear()
  const mm = date.getMonth()
  const dd = date.getDate()

  useEffect(() => {
    state.slider.status === 'idel' && state.slider.items.forEach((image) => {
      const img = new Image()
      img.src = image.src
      // img.src = preferWebp(image.src, "png");
    })
  }, [state.slider])

  useEffect(() => {
    getDocuments("slider")
    getDocuments("options")
    getDocuments("modelsList")
  }, [])

  useEffect(() => {
    const unSubCities = onSnapshot(query(collection(db, 'cities')), querySnapshot => {
      var items = []
      querySnapshot.forEach((snapDoc) => {
        if (snapDoc.exists) {
          items.push({
            ...snapDoc.data(),
            id: snapDoc.id
          })
        }
      })
      dispatch({
        type: "cities",
        items: items,
        status: "idel"
      })
    })
    const unSubEvents = onSnapshot(query(collection(db, 'events'),
      where('from', '>=', new Date(yyyy, mm, dd)),
      where('from', '<=', new Date(yyyy, mm + 1, 0))
    ), querySnapshot => {
      var items = []
      querySnapshot.forEach((snapDoc) => {
        if (snapDoc.exists) {
          items.push({
            ...snapDoc.data(),
            id: snapDoc.id
          })
        }
      })
      dispatch({
        type: "events",
        items: items,
        status: "idel"
      })
    })

    return () => {
      unSubEvents()
      unSubCities()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDocuments = (table) => {
    if (!table) {
      // new Throw("Table is required")
      return
    }
    getDocs(collection(db, table)).then(querySnapshot => {
      var items = []
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists) {
          items.push({
            ...docSnap.data(),
            id: docSnap.id
          })
        }
      })
      dispatch({
        type: table,
        items: items,
        status: "idel"
      })
    })
  }

  const getDocument = (table, id) => new Promise((resolve, reject) => {
    if (!table) {
      reject("Table is required")
      return
    }
    if (!id) {
      reject("id is required")
      return
    }
    getDoc(doc(collection(db, table), id))
      .then(docSnap => resolve({ id: docSnap.id, ...docSnap.data() }))
      .catch(error => {
        reject(error)
      })
  })

  const getModelsByMake = (make, table = "models") => {
    dispatch({
      type: table,
      items: [],
      status: "loading"
    })

    getDocs(query(collection(db, table), where("make", "==", make))).then(querySnapshot => {
      var items = []
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists) {
          items.push({
            ...docSnap.data(),
            id: docSnap.id
          })
        }
      })
      dispatch({
        type: table,
        items: items,
        status: "idel"
      })
    })
  }

  const getModelsByModelName = (model, table = "models") => {
    dispatch({
      type: table,
      items: [],
      status: "loading"
    })

    getDocs(query(collection(db, table), where("model", "==", model), orderBy("year", "asc"))).then(querySnapshot => {
      var items = []
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists) {
          items.push({
            ...docSnap.data(),
            id: docSnap.id
          })
        }
      })
      dispatch({
        type: table,
        items: items,
        status: "idel"
      })
    })
  }

  const getOptions = (id, defaultValue = {}) => {
    if (state.options.status !== 'loading') {
      const res = state.options.items.filter(item => item.id === id)
      if (res && res[0]) {
        return res[0][id]
      }
      return defaultValue
    }
    return defaultValue
  }

  // console.log("Firestore", state)

  return (
    <FirestoreContext.Provider value={{
      ...state, dispatch, getDocuments, getDocument, getModelsByMake, getModelsByModelName, getOptions
    }}>
      {children}
    </FirestoreContext.Provider>
  )
}
