import React from 'react'
import loader from "../assets/loader.svg"

const Loader = () => (
  <div id="loader" className="loader-container loader-overlay loader-fixed" style={{ display: "none" }}>
    <img src={loader} className="loader" alt="Loading..." />
  </div>
)

export default Loader

export const LoaderSuspense = () => (
  <div className="loader-container loader-overlay loader-fixed">
    <img src={loader} className="loader" alt="Loading..." />
  </div>
)

export const LoaderBlock = (props) => (
  <div className='loader-container' {...props}>
    <img src={loader} className="loader" alt="Loading..." />
  </div>
)

export const LoaderInline = () => (
  <img src={loader} className="loader" alt="Loading..." />
)

export const showLoader = (loading) => {
  let loader = document.getElementById('loader')
  if (loader) {
    if (loader && loading === true) {
      loader.style.display = "flex"
    } else {
      loader.style.display = "none"
    }
  }
}