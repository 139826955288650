import React, { useContext, useEffect, useReducer } from "react"
import PropTypes from 'prop-types'
import { collection, onSnapshot, orderBy, query, increment, updateDoc, doc } from "@firebase/firestore"

import { db } from "../../firebase"
import { defaultList } from "../util/defaultData"

const Store = React.createContext()

const productsRef = "products"
const taxonomiesRef = "taxonomy"

export function useProduct() {
  return useContext(Store)
}

const initialState = {
  products: defaultList,
  services: defaultList,
  categories: defaultList,
  taxonomies: defaultList
}

function reducer(state, action) {
  switch (action.type) {
    case "products": {
      return {
        ...state,
        products: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "taxonomies": {
      let services = action.items.filter(item => item.type === 'services').sort((a, b) => a.order - b.order)
      let categories = action.items.filter(item => item.type === 'category').sort((a, b) => a.order - b.order)
      // services.sort((a, b) => a.slug < b.slug)
      return {
        ...state,
        taxonomies: {
          items: action.items,
          status: action.status
        },
        services: {
          items: services,
          status: action.status
        },
        categories: {
          items: categories,
          status: action.status
        }
      }
    }
    default:
      return state
  }
}

export default function ProductProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    // const unSubProducts = onSnapshot(query(collection(db, productsRef), orderBy("category")), querySnapshot => {
    const unSubProducts = onSnapshot(query(collection(db, productsRef), orderBy("createdAt", "desc")), querySnapshot => {
      var items = []
      querySnapshot.forEach((snapDoc) => {
        if (snapDoc.exists) {
          items.push({
            ...snapDoc.data(),
            uid: snapDoc.id
          })
        }
      })
      dispatch({
        type: "products",
        items: items,
        status: "idel"
      })
    })

    const unSubTaxonomy = onSnapshot(query(collection(db, taxonomiesRef), orderBy("label")), querySnapshot => {
      var items = []
      querySnapshot.forEach((snapDoc) => {
        if (snapDoc.exists) {
          items.push({
            ...snapDoc.data(),
            uid: snapDoc.id
          })
        }
      })
      dispatch({
        type: "taxonomies",
        items: items,
        status: "idel"
      })
    })

    return () => {
      unSubProducts()
      unSubTaxonomy()
    }
  }, [])

  const reduceProductQty = (uid, qty) => {
    return updateDoc(doc(db, productsRef, uid), "qty", increment(-qty))
  }

  // console.log("ProductContext", state)

  return (
    <Store.Provider value={{
      ...state,
      dispatch,
      reduceProductQty
    }}>
      {children}
    </Store.Provider>
  )
}


ProductProvider.propTypes = {
  children: PropTypes.node.isRequired
}