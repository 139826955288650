import React, { useContext, useReducer } from "react"
import PropTypes from 'prop-types'
import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, serverTimestamp, updateDoc, where } from "@firebase/firestore"

import { db } from "../../firebase"
import { showLoader } from "../../utils/loader"
import { useCart, useECommerceStore, useProduct } from ".."
import { addMinutes } from "date-fns"

const initialState = {
  order: {
    status: "loading"
  },
  orders: {
    status: "loading",
    items: [{
      id: "abcd",
      items: [
        { name: "Loading" }
      ],
      tax: 0,
      promo: 0,
      total: 0,
      subTotal: 0
    }]
  }
}

const Store = React.createContext()

const ordersRef = "orders"

export function useOrder() {
  return useContext(Store)
}

function reducer(state, action) {
  switch (action.type) {
    case "orders": {
      return {
        ...state,
        orders: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "order": {
      return {
        ...state,
        order: { ...action.item, status: action.status }
      }
    }
    default:
      return state
  }
}

export default function OrderProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { currency } = useECommerceStore()
  const { cart, removeFromCart, summary, subTotal, total } = useCart()
  const { reduceProductQty } = useProduct()

  const createOrder = (uid, orderData) => new Promise((resolve, reject) => {
    orderData.uid = uid
    orderData.createdAt = serverTimestamp()
    if (cart.status !== 'loading') {
      orderData.items = cart?.items
      orderData.summary = summary?.items
      orderData.subTotal = subTotal
      orderData.total = total
      orderData.currency = currency
    } else {
      reject("Cart is not ready.")
    }

    // console.log("createOrder", orderData)
    showLoader(true)
    return addDoc(collection(db, ordersRef), orderData)
      .then(docData => {
        const order = { oid: docData.id, ...orderData }
        dispatch({
          type: "order",
          item: order,
          status: "idel"
        })
        const time = order?.items.reduce((prev, cur) => prev + (cur?.service?.hour || 0), 0)
        addDoc(collection(db, 'events'), {
          oid: docData.id,
          title: `Order From ${order.firstName}`,
          from: order.appoinment,
          to: time > 0 ? addMinutes(new Date(order.appoinment), time * 60) : 30,
          createdAt: serverTimestamp()
        })
        resolve(order)
      })
      .catch(error => reject(error))
      .finally(() => {
        showLoader(false)
      })
  })

  const complateOrder = (oid, status) => new Promise((resolve, reject) => {
    showLoader(true)
    return updateDoc(doc(db, ordersRef, oid), "status", status)
      .then(data => {
        for (let item of data.items) {
          if (item.pid) {
            reduceProductQty(item.pid, item.qty)
          }
          if (item.cid) {
            removeFromCart(item.cid, false)
          }
        }
        resolve(data)
      })
      .catch(error => reject(error))
      .finally(() => {
        showLoader(false)
      })
  })

  const getOrders = (uid) => {
    onSnapshot(query(collection(db, ordersRef), orderBy("createdAt", "desc"), where("uid", "==", uid)), querySnapshot => {
      var items = []
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          items.push({
            ...doc.data(),
            oid: doc.id
          })
        }
      })
      dispatch({
        type: "orders",
        items: items,
        status: "idel"
      })
    })
  }

  const cancelOrder = (oid) => {
    showLoader(true)
    return updateDoc(doc(db, ordersRef, oid), "status", "Cancel").finally(() => {
      showLoader(false)
    })
  }

  const getOrder = async (oid) => new Promise((resolve) => {
    if (oid) {
      if (state.orders.status !== "loading") {
        let tmpOrder = state.orders.items.find(item => item.oid === oid)
        dispatch({
          type: "order",
          item: tmpOrder,
          status: "idel"
        })
        resolve(tmpOrder)
      } else {
        // for imedetly get order.
        getDoc(doc(collection(db, ordersRef), oid))
          .then(docData => {
            dispatch({
              type: "order",
              item: { oid: docData.id, ...docData.data() },
              status: "idel"
            })
            resolve({ oid: docData.id, ...docData.data() })
          })
      }
    }
  })

  const flushOrder = () => {
    dispatch({
      type: "order",
      item: {},
      status: "loading"
    })
  }

  const itemsCount = (items) => {
    if (items && items.length > 1) {
      return `for ${items.length} items`
    } else {
      return 'for 1 item'
    }
  }

  // console.log("OrderContext", state)

  return (
    <Store.Provider value={{
      ...state,
      dispatch,
      getOrders,
      getOrder,
      createOrder,
      complateOrder,
      cancelOrder,
      flushOrder,
      itemsCount
    }}>
      {children}
    </Store.Provider>
  )
}

OrderProvider.propTypes = {
  children: PropTypes.node.isRequired
}