import { ref, listAll, updateMetadata } from "firebase/storage"
import { storage } from "."


const storageControl = (url) => {
  if (!url) {
    alert("url is not provided.")
    return;
  }
  var listRef = ref(storage, url)
  console.log("listRef", listRef)
  // Find all the prefixes and items.
  listAll(listRef)
    .then((res) => {
      res.prefixes.forEach((folderRef) => {
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
        console.log(folderRef)
      })
      res.items.forEach((itemRef) => {
        console.log(itemRef)

        // All the items under listRef.
        // Create file metadata to update
        var newMetadata = {
          // cacheControl: 'public,max-age=300',
          cacheControl: ' public, max-age=30240000, immutable', // 1 year
          // cacheControl: ' public, max-age=2592000, immutable', // 1 month
          // cacheControl: ' public, max-age=604800, immutable', // 1 week
          // contentType: 'image/jpeg'
        }
        // Update metadata properties
        updateMetadata(itemRef, newMetadata)
          .then((metadata) => {
            // Updated metadata for 'images/forest.jpg' is returned in the Promise
            console.log(metadata)

          }).catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error)
          })

        // itemRef.getMetadata()
        //   .then((metadata) => {
        //     // Metadata now contains the metadata for 'images/forest.jpg'
        //     console.log(metadata)
        //   })
        //   .catch((error) => {
        //     // Uh-oh, an error occurred!
        //     console.log(error)
        //   });

        // itemRef.getDownloadURL().then(res => {
        //   console.log(res);
        // });
      })
    }).catch((error) => {
      console.log(error)
      // Uh-oh, an error occurred!
    })
}

// import regularMainatanance from "../assets/services/vehicle-regular-mainanance-320.png"
// import fluidThumb from "../assets/services/fluid-service-320.png"
// import tyreThumb from "../assets/services/tyre-service-320.png"
// import batteryThumb from "../assets/services/battery-service-320.png"
// import generalMaintenanceThumb from "../assets/services/genearal-mainanance-320.png"
// import specialServicesThumb from "../assets/services/special-service-320.png"

// export const services = {
//   fluid: fluidThumb,
//   tyre: tyreThumb,
//   battery: batteryThumb,
//   service: regularMainatanance,
//   maintanance: generalMaintenanceThumb,
//   specialService: specialServicesThumb,
// }

// import servicesBg from "../assets/services.png"
// import contactBg from "../assets/banners/banner-contact.jpg"
// import aboutBg from "../assets/about.png"

// const assetsRef = ref(storage, '/assets').toString().replace("gs://", "https://storage.googleapis.com/")

// const imagesRef = ref(storage, '/images').toString().replace("gs://", "https://storage.googleapis.com/")
// const bannersRef = ref(storage, '/banners').toString().replace("gs://", "https://storage.googleapis.com/")
// const categoriesRef = ref(storage, '/categories').toString().replace("gs://", "https://storage.googleapis.com/")

const assets = {
  logo: "https://storage.googleapis.com/fixwagen.appspot.com/assets/logo",
  logoHeader: "https://storage.googleapis.com/fixwagen.appspot.com/assets/logo-sm",
}
const banners = {
  default: "https://storage.googleapis.com/fixwagen.appspot.com/banners/banner-services.jpg",
  // contact: contactBg,
  contact: "https://storage.googleapis.com/fixwagen.appspot.com/banners/banner-contact.jpg",
  about: "https://storage.googleapis.com/fixwagen.appspot.com/banners/banner-about.jpg",
  service: "https://storage.googleapis.com/fixwagen.appspot.com/banners/banner-services.jpg"
}

// const categories = {
//   "category": `${categoriesRef}/`,
//   "vegetarian": `${categoriesRef}/vegetarian.jpg`,
//   "pescatarian": `${categoriesRef}/pescatarian.jpg`,
//   "vegan": `${categoriesRef}/vegan.jpg`,
//   "keto": `${categoriesRef}/keto.jpg`,
//   "mediterranean": `${categoriesRef}/mediterranean.jpg`,
//   "kids": `${categoriesRef}/kids.jpg`,
//   "healthy": `${categoriesRef}/healthy.jpg`,
// }

const slides = [
  {
    src: "https://storage.googleapis.com/fixwagen.appspot.com/slides/slide-toyota-hilux",
    title: "Hilux",
    description: "Toyota"
  },
  {
    src: "https://storage.googleapis.com/fixwagen.appspot.com/slides/slide-toyota-corolla",
    title: "Corolla",
    description: "Toyota"
  },
  {
    src: "https://storage.googleapis.com/fixwagen.appspot.com/slides/slide-toyota-fortuner",
    title: "Fortuner",
    description: "Toyota"
  },
  {
    src: "https://storage.googleapis.com/fixwagen.appspot.com/slides/slide-toyota-avanza",
    title: "Avanza",
    description: "Toyota"
  },
  {
    src: "https://storage.googleapis.com/fixwagen.appspot.com/slides/slide-land-cruiser",
    title: "Land Cruiser",
    description: "Toyota"
  }
]

export {
  storageControl,
  assets,
  banners,
  slides
}