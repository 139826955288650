import { isNumber } from "../util/number"

const useTax = () => {
  const calculateTax = (total, rate) => {
    const val = isNumber(total) ? total : 0
    const tax = isNumber(rate) ? rate : 0
    return val * (tax / 100)
  }

  const totalWithTax = (subTotal, rate) => {
    const val = isNumber(subTotal) ? subTotal : 0
    const tax = isNumber(rate) ? rate : 0
    return val + (val * (tax / 100))
  }

  return {
    calculateTax,
    totalWithTax
  }
}

export default useTax


export const calculateTax = (total, rate) => {
  const val = isNumber(total) ? total : 0
  const tax = isNumber(rate) ? rate : 0
  return val * (tax / 100)
}

export const totalWithTax = (subTotal, rate) => {
  const val = isNumber(subTotal) ? subTotal : 0
  const tax = isNumber(rate) ? rate : 0
  return val + (val * (tax / 100))
}
