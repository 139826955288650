import React from "react"
// import { Link } from "react-router-dom"

import { useClient } from "../context/ClientContext"
import { assets } from "../firebase/storage"

const ComingSoon = () => {
  const { preferWebp } = useClient()
  return (
    <div className='d-flex justify-content-center align-items-center flex-column' style={{
      height: '100vh',
    }}>
      <img src={preferWebp(assets?.logo, "png")} style={{ height: "12em" }} alt="" />

      {/* <h1 className='text-uppercase'>Coming Soon</h1> */}
      <h4 className='text-capitalize mb-5'>Coming Soon</h4>
      {/* <Link className='btn btn-dark' to='/'>
        Back To Home
      </Link> */}
    </div>
  )
}

export default ComingSoon
