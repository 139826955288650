import React, { useContext, useEffect, useReducer } from "react"
import { collection, onSnapshot, query } from "@firebase/firestore"

import ProductProvider from "./ProductContext"
import CartProvider from "../context/CartContext"
import OrderProvider from "../context/OrderContext"

import { db } from "../../firebase"
import { defaultCurrency, defaultList, defaultTax } from "../util/defaultData"

const Store = React.createContext()

const otionsRef = "options"

export function useECommerceStore() {
  return useContext(Store)
}

const initialState = {
  options: defaultList,
  tax: defaultTax,
  currency: defaultCurrency
}

function reducer(state, action) {
  switch (action.type) {
    case "options": {
      let tax = action.items.find(item => item.type === 'tax') || defaultTax
      let currency = action.items.find(item => item.type === 'currency') || defaultCurrency
      return {
        ...state,
        options: {
          items: action.items,
          status: action.status
        },
        tax: {
          name: tax.name,
          rate: tax.value
        },
        currency: {
          name: currency.name,
          iso: currency.value
        }
      }
    }
    default:
      return state
  }
}

export default function ECommerceProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const unSubOptions = onSnapshot(query(collection(db, otionsRef)), querySnapshot => {
      var items = []
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          items.push({
            ...doc.data(),
            uid: doc.id
          })
        }
      })
      dispatch({
        type: "options",
        items: items,
        status: "idel"
      })
    })

    return () => {
      unSubOptions()
    }
  }, [])

  // console.log("ECommerceContext", state)

  return (
    <Store.Provider value={{
      ...state,
      dispatch
    }}>
      <ProductProvider>
        <CartProvider>
          <OrderProvider>
            {children}
          </OrderProvider>
        </CartProvider>
      </ProductProvider>
    </Store.Provider>
  )
}
