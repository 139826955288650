import React from "react"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"

// styles
import "./assets/styles/index.scss"

// routes
import Routes from "./routes/Routes"

import Loader, { LoaderSuspense } from "./utils/loader"
// import { StoreProvider } from "./context/StoreContext"
// import { EStoreProvider } from "./eCommerce/context/EStoreContext"
import AuthProvider from "./context/AuthContext"
import ClientProvider from "./context/ClientContext"
import FirestoreProvider from "./context/FirestoreContext"
import ECommerceProvider from "./eCommerce/context/ECommerceContext"

function App() {
  return (
    <React.Suspense fallback={<LoaderSuspense />}>
      <ClientProvider>
        <FirestoreProvider>
          {/* <StoreProvider> */}
          <ECommerceProvider>
            {/* <EStoreProvider> */}
            <AuthProvider>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
              <Loader />
              <ToastContainer />
            </AuthProvider>
            {/* </EStoreProvider> */}
            {/* </StoreProvider> */}
          </ECommerceProvider>
        </FirestoreProvider>
      </ClientProvider>
    </React.Suspense>
  )
}

export default App
