import React, { useContext, useEffect, useReducer } from "react"
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updatePassword
} from "@firebase/auth"
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  serverTimestamp
} from "@firebase/firestore"
import { toast } from "react-toastify"
import { showLoader } from "../utils/loader"

const auth = getAuth()
const db = getFirestore()

const usersRef = "users"
const modelsRef = "models"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

const initialState = {
  status: "loading",
  user: {
    uid: null,
    displayName: "Loading...",
    cars: [],
  },
  carDetails: [],
  logged: false,
  claims: []
}

function reducer(state, action) {
  switch (action.type) {
    case "login": {
      return {
        ...state,
        user: {
          ...action.profile,
          uid: action.uid,
          // displayName: action.displayName,
          // cars: action.cars,
        },
        status: action.status,
        logged: true
      }
    }
    case "updateProfile": {
      return {
        ...state,
        user: {
          ...state.user, ...action.updated
        },
        status: action.status,
      }
    }
    case "selectCarDetails": {
      return {
        ...state,
        carDetails: action.carDetails,
        status: action.status,
      }
    }
    case "clearCars": {
      return {
        ...state,
        carDetails: [],
        user: {
          ...state.user, cars: action.cars
        },
        status: action.status,
      }
    }
    case "authError": {
      return {
        ...state,
        status: action.status
      }
    }
    case "claims": {
      return {
        ...state,
        claims: action.claims
      }
    }
    case "logout": {
      return {
        ...initialState,
        status: "idel"
      }
    }
    default:
      return state
  }
}

export default function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const getCarDetails = (uid, cars) => {
    if (cars.length > 0) {
      const car = cars.find(car => car?.default === true)
      const carId = car?.id ? car?.id : cars[0].id
      getDoc(doc(collection(db, modelsRef), carId))
        .then((docSnap) => {
          if (docSnap.data()) {
            dispatch({
              type: "selectCarDetails",
              carDetails: { id: docSnap.id, ...docSnap.data() },
              status: "idel"
            })
          } else {
            toast.error("The car you have selected is not exists. or removed")
            // toast.error(state.user.uid)
            let updatedCars = cars.filter(car => car?.id !== carId)
            updateDoc(doc(db, usersRef, uid), { cars: updatedCars })
              .then(() => {
                dispatch({
                  type: "clearCars",
                  cars: updatedCars,
                  status: "idel"
                })
                window.location.href = '/myaccount/car'
              })
          }
        })
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        let user = currentUser
        const profile = await getDoc(doc(collection(db, usersRef), user.uid)).then(doc => doc.data())

        dispatch({
          type: "login",
          uid: user.uid,
          // displayName: user.providerData[0]?.displayName || "User",
          // displayName: user.providerData[0].displayName,
          // profile: user.providerData[0],
          profile: profile,
          // cars: userData?.cars,
          // carDetails: carDetails,
          // profile: user,
          status: "idel"
        })

        if (profile?.cars) {
          getCarDetails(user.uid, profile.cars)
          // } else {
          //   toast.info("Please select car to get reccomenation.")
        }

        currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            showLoader(false)
            dispatch({
              type: "claims",
              claims: idTokenResult.claims,
            })

            // setClaims(idTokenResult.claims)
            // console.log("claims", idTokenResult.claims)
            // // Confirm the user is an Admin.
            // if (!!idTokenResult.claims.admin) {
            //   console.log("admin")
            //   // Show admin UI.
            //   // showAdminUI()
            // } else {
            //   // Show regular user UI.
            //   // showRegularUI()
            // }
          })
          .catch((error) => {
            showLoader(false)
            console.log(error)
          })
      } else {
        dispatch({
          type: "authError",
          status: "idel"
        })
        showLoader(false)
      }
    }, error => {
      dispatch({
        type: "authError",
        status: "idel"
      })
      console.log(error)
      showLoader(false)
    })
    return unsubscribe
  }, [])

  /**
   *
   * @param {*} email
   * @param {*} password
   * @returns
   */
  function signup(email, password, profile) {
    showLoader(true)
    let tid = toast.loading("Registering...")
    return new Promise((resolve, reject) => createUserWithEmailAndPassword(auth, email, password)
      .then(async userCredential => {
        // Signed in
        const user = userCredential.user
        // console.log(user)

        profile.createdAt = serverTimestamp()
        setDoc(doc(collection(db, usersRef), user.uid), profile)

        // updateProfile(user, {
        //   displayName: profile.displayName,
        //   phoneNumber: profile.mobile,
        //   // photoURL: "",
        //   // claims: {
        //   //   "admin": true,
        //   //   "staff": true
        //   // }
        //   // photoURL: "https://example.com/jane-q-user/profile.jpg"
        // })
        // const collection(db, usersRef) = collection(db, "users")
        // const userData = await getDoc(doc(collection(db, usersRef), user.uid)).then(doc => doc.data())

        dispatch({
          type: "login",
          uid: user.uid,
          // displayName: user.providerData[0]?.displayName || "User",
          // profile: user.providerData[0],
          profile: profile,
          // cars: [],
          // profile: userData,
          // cars: userData?.cars,
          // profile: user,
          status: "idel"
        })
        toast.update(tid, { render: "Registred successfully.", type: "success", isLoading: false, autoClose: true })
        return resolve(user)
        // return user
      })
      .catch(error => {
        var errorCode = error.code
        var errorMessage = error.message
        if (errorCode === 'auth/email-already-in-use') {
          errorMessage = "This email is allready registered. Please try to login with it."
        }
        if (errorCode === 'auth/wrong-password') {
          errorMessage = "Wrong password."
        }
        toast.update(tid, { render: errorMessage, type: "error", isLoading: false, autoClose: true })
        reject(error)
      })
      .finally(() => {
        showLoader(false)
      }))
  }

  /**
   *
   * @param {*} email
   * @param {*} password
   * @returns Promise<user>
   */
  const login = (email, password) => new Promise((resolve) => {
    showLoader(true)
    const tid = toast.loading("Authenticating...")
    signInWithEmailAndPassword(auth, email, password)
      .then(async userCredential => {
        // Signed in
        const user = userCredential.user
        // let carDetails = []
        const profile = await getDoc(doc(collection(db, usersRef), user.uid)).then(doc => doc.data())
        // if (userData?.cars.length > 0) {
        //   carDetails = await getDoc(doc(collection(db, modelsRef), userData?.cars[0].id)).then(doc => doc.data())
        // }

        dispatch({
          type: "login",
          uid: user.uid,
          // displayName: user.providerData[0].displayName,
          profile: profile,
          // cars: profile?.cars,
          // carDetails: carDetails,
          // profile: user.providerData[0],
          status: "idel"
        })

        if (profile?.cars) {
          getCarDetails(user.uid, profile.cars)
        }
        // showLoader(false)
        toast.update(tid, { render: "Logged in successfully.", type: "success", isLoading: false, autoClose: true })
        return resolve(profile)
        // return user
      })
      .catch(error => {
        var errorCode = error.code
        var errorMessage = error.message
        if (errorCode === 'auth/wrong-password') {
          errorMessage = "Wrong password."
        }
        if (errorCode === 'auth/user-disabled') {
          errorMessage = "Your account is blocked."
        }
        toast.update(tid, { render: errorMessage, type: "error", isLoading: false, autoClose: true })
      })
      .finally(() => {
        showLoader(false)
      })
  })

  const logout = () => new Promise((resolve) => {
    showLoader(true)
    let tid = toast.loading("Requesting...")
    signOut(auth)
      .then(() => {
        dispatch({ type: "logout" })
        toast.update(tid, { render: "Logged out successfully.", type: "success", isLoading: false, autoClose: true })
        return resolve()
      })
      .catch((error) => {
        var errorMessage = error.message
        toast.update(tid, { render: errorMessage, type: "error", isLoading: false, autoClose: true })
      })
      .finally(() => {
        showLoader(false)
      })
  })

  const forgotPassword = (email) => new Promise((resolve) => {
    showLoader(true)
    const tid = toast.loading("Requesting...")
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password Reset Email Sent!
        toast.update(tid, { render: "Password Reset Email Sent!", type: "success", isLoading: false, autoClose: true })
        return resolve()
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        if (errorCode === 'auth/invalid-email') {
          errorMessage = "Email is not valid."
        } else if (errorCode === 'auth/user-not-found') {
          errorMessage = "User not found with this email."
        }
        toast.update(tid, { render: errorMessage, type: "error", isLoading: false, autoClose: true })
        console.log(error)
      })
      .finally(() => {
        showLoader(false)
      })
  })

  // function changeEmail(email) {
  //   return updateEmail(user, email)
  // }

  // function changePassword(password) {
  //   return updatePassword(user, password)
  // }


  const updateAuthPassword = (newPassword) => new Promise((resolve) => {
    const tid = toast.loading("Updating...")

    const user = auth.currentUser
    showLoader(true)
    updatePassword(user, newPassword)
      .then(() => {
        toast.update(tid, { render: "Password Updated successfully.", type: "success", isLoading: false, autoClose: true })
        return resolve()
      })
      .catch((error) => {
        var errorMessage = error.message
        toast.update(tid, { render: errorMessage, type: "error", isLoading: false, autoClose: true })
      })
      .finally(() => {
        showLoader(false)
      })
  })

  const updateAuthProfile = (profile) => {
    updateProfile(auth.currentUser, profile).then(() => {
      console.log("Updated")
    }).catch((error) => {
      console.log(error)
    })
  }

  const updateUserProfile = (uid, payload) => new Promise((resolve) => {
    const tid = toast.loading("Updating...")
    showLoader(true)
    updateDoc(doc(db, usersRef, uid), payload)
      .then(() => {
        toast.update(tid, { render: "Updated successfully.", type: "success", isLoading: false, autoClose: true })
        dispatch({
          type: "updateProfile",
          updated: payload,
          status: "idel"
        })
        if (payload?.cars && payload?.cars) {
          getCarDetails(uid, payload?.cars)
        }
        return resolve()
      })
      .catch((error) => {
        var errorMessage = error.message
        toast.update(tid, { render: errorMessage, type: "error", isLoading: false, autoClose: true })
      })
      .finally(() => {
        showLoader(false)
      })
  })

  const addCar = (newCar) => new Promise((resolve) => {
    const tid = toast.loading("Adding car...")
    showLoader(true)
    state?.user?.cars?.push(newCar)
    const payload = { cars: state?.user?.cars }

    updateDoc(doc(db, usersRef, state.user.uid), payload)
      .then(() => {
        toast.update(tid, { render: "Car added successfully.", type: "success", isLoading: false, autoClose: true })
        dispatch({
          type: "updateProfile",
          updated: payload,
          status: "idel"
        })
        if (payload?.cars && payload?.cars) {
          getCarDetails(state.user.uid, payload?.cars)
        }
        return resolve()
      })
      .catch((error) => {
        var errorMessage = error.message
        toast.update(tid, { render: errorMessage, type: "error", isLoading: false, autoClose: true })
      })
      .finally(() => {
        showLoader(false)
      })
  })

  const removeCar = (carId) => new Promise((resolve) => {
    const tid = toast.loading("Removing...")
    showLoader(true)
    const payload = { cars: state?.user?.cars?.filter(car => car.id !== carId) }

    updateDoc(doc(db, usersRef, state.user.uid), payload)
      .then(() => {
        toast.update(tid, { render: "Car removed successfully.", type: "success", isLoading: false, autoClose: true })
        dispatch({
          type: "updateProfile",
          updated: payload,
          status: "idel"
        })
        if (payload?.cars && payload?.cars) {
          getCarDetails(state.uid, payload?.cars)
        }
        return resolve()
      })
      .catch((error) => {
        var errorMessage = error.message
        toast.update(tid, { render: errorMessage, type: "error", isLoading: false, autoClose: true })
      })
      .finally(() => {
        showLoader(false)
      })
  })

  const setDefaultCar = (carId) => new Promise((resolve) => {
    const tid = toast.loading("Updating...")
    showLoader(true)
    const payload = { cars: state.user.cars.map(car => car.id === carId ? ({ ...car, default: true }) : ({ ...car, default: false })) }

    updateDoc(doc(db, usersRef, state.user.uid), payload)
      .then(() => {
        toast.update(tid, { render: "Car changed.", type: "success", isLoading: false, autoClose: true })
        dispatch({
          type: "updateProfile",
          updated: payload,
          status: "idel"
        })
        if (payload?.cars && payload?.cars) {
          getCarDetails(state.uid, payload?.cars)
        }

        return resolve()
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        showLoader(false)
      })
  })

  // console.log("AuthContext", state)

  return (
    <AuthContext.Provider value={{
      ...state,
      dispatch,
      signup,
      login,
      logout,
      forgotPassword,
      updateAuthProfile,
      updateAuthPassword,
      updateUserProfile,
      addCar,
      removeCar,
      setDefaultCar
    }}>
      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
      {children}
    </AuthContext.Provider>
  )
}
