import React from "react"
import { Route, Switch } from "react-router-dom"

// components
import ScrollToTop from "../components/ScrollToTop"

// pages
import ComingSoon from "../pages/ComingSoon"

const Routes = () => {
  return (
    <ScrollToTop>
      <Switch>
        <Route path="*">
          <ComingSoon />
        </Route>
      </Switch>
    </ScrollToTop>
  )
}

export default Routes
